import { gql } from '@apollo/client'

export const FEEDBACK_MUTATION = gql`
  mutation WebFeedback(
    $file: Upload
    $name: String!
    $email: String!
    $subject: String
    $url: String!
    $message: String!
  ) {
    webFeedback(
      file: $file
      name: $name
      email: $email
      subject: $subject
      url: $url
      message: $message
    )
  }
`
