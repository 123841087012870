import React, {useRef, useState} from 'react';
import {Col, Row, Alert} from 'react-bootstrap';
import {Mutation} from 'react-apollo';

import styles from './feedback.module.scss';
import Layout from '../../components/layout/layout';
import {FEEDBACK_MUTATION} from '../../apollo/mutations/feedback';
const Feedback = ({location}) => {
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    url: '',
    message: '',
    file: null,
  });
  const [inputInUse] = useState(false);
  const [formErrors, setFormErrors] = useState({
    nameError: '',
    emailError: '',
    subjectError: '',
    messageError: '',
  });

  const hiddenFileInput = useRef(null);

  const uploadFile = () => {
    hiddenFileInput.current.click();
  };

  const validate = () => {
    let nameError = '';
    let emailError = '';
    let subjectError = '';
    let messageError = '';

    if (state.name.trim().length === 0) {
      nameError = `This field can't be empty`;
    }
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (state.email.trim().length === 0) {
      emailError = `This field can't be empty`;
    } else if (!state.email.trim().match(emailRegex)) {
      emailError = 'Please type in correct email address';
    }

    if (state.subject.trim().length === 0) {
      subjectError = `This field can't be empty`;
    }

    if (state.message.trim().length === 0) {
      messageError = `This field can't be empty`;
    }

    setFormErrors({
      nameError: nameError,
      emailError: emailError,
      subjectError: subjectError,
      messageError: messageError,
    });
    return !(nameError || emailError || subjectError || messageError);
  };

  const changeFile = (event) => {
    setState({...state, file: event.target.files[0]});
  };

  const handleInputChange = (event) => {
    setState({...state, [event.target.name]: event.target.value});
  };

  return (
    <Layout location={location} inputInUse={inputInUse}>
      <div className={styles.feedback}>
        <h1>Feedback</h1>
        <p>
          CELLR has never been a vanity project for its founders. It’s been a
          commitment to bring the best wine lifestyle platform to our users and
          allow them to curate their wine life as they see fit. For CELLR to be
          our community’s crossroads for all things wine.
        </p>
        <p>
          If you have a suggestion for new functions, see an error in a
          particular area, want to suggest a new winery partnership, or anything
          that will make your wine life more enjoyable…we want to hear about it!
        </p>
        <Mutation mutation={FEEDBACK_MUTATION}>
          {(sendFeedback, {loading, error, data}) => (
            <React.Fragment>
              {data && (
                <Alert className={styles.alert}>{data?.webFeedback}</Alert>
              )}
              {error && (
                <Alert className={styles.alert}>
                  {error?.message + ' Try Again!'}
                </Alert>
              )}

              <form
                onSubmit={async (event) => {
                  event.preventDefault();
                  const isValid = validate();
                  if (isValid) {
                    sendFeedback({
                      variables: {
                        file: state.file,
                        name: state.name,
                        email: state.email,
                        subject: state.subject,
                        url: state.url,
                        message: state.message,
                      },
                    });
                  }
                }}
                autoComplete="off"
                className={styles.feedback__form}
              >
                <Row className={styles.feedback__fields}>
                  <Col xs={12} lg={6} className={styles.feedback__column}>
                    <div>
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={state.name}
                        onChange={handleInputChange}
                      />
                      {formErrors.nameError ? (
                        <span>{formErrors.nameError}</span>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={state.email}
                        onChange={handleInputChange}
                      />
                      {formErrors.emailError ? (
                        <span>{formErrors.emailError}</span>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        value={state.subject}
                        onChange={handleInputChange}
                      />
                      {formErrors.subjectError ? (
                        <span>{formErrors.subjectError}</span>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="text"
                        name="url"
                        placeholder="URL"
                        value={state.url}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} lg={6} className={styles.feedback__column}>
                    <div>
                      <textarea
                        name="message"
                        placeholder="Message"
                        value={state.message}
                        onChange={handleInputChange}
                      />
                      {formErrors.messageError ? (
                        <span>{formErrors.messageError}</span>
                      ) : null}
                    </div>

                    <button onClick={uploadFile} type="button">
                      <p>Upload file</p>
                      <p>{state.file?.name}</p>
                    </button>

                    {/* This input is only for the possibility of choosing a file. Never displayed! */}
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/heic"
                      ref={hiddenFileInput}
                      onChange={changeFile}
                      style={{display: 'none'}}
                    />
                  </Col>
                </Row>
                <div className={styles.feedback__submit}>
                  <button disabled={loading} variant="contained" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </React.Fragment>
          )}
        </Mutation>
      </div>
    </Layout>
  );
};

export default Feedback;
